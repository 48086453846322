
export const data=[
    {
        id:0,
        name:"PortaByte",
        description:"A full-stack file-sharing app designed for secure and efficient file management. It enables users to upload, download, and share files via email. Other key features include JWT-based authentication and email verification infrastructure powered by nodemailer.",
        github:"https://github.com/UjjvalRajput/PortaByteBackend",
        techStack:"Next.js, Redux Toolkit, Node.js, Express.js, MongoDB, Amazon Web Services (AWS) S3, JWT, Postman",
        show:"https://porta-byte-frontend.vercel.app",
    },
    {
        id:1,
        name:"Adventure Game",
        description:"Developed a GUI-based game in a team of four, utilizing Model-View-Controller design pattern and Agile methodologies. Features include customizable controls, mini-games, and maze navigation to find treasure while avoiding traps.",
        github:"https://github.com/UjjvalRajput/Adventure-Game",
        techStack:"Java, JavaFX, JUnit, Eclipse IDE",
        show:"https://youtu.be/CVvTZHIRXk0",
    },
    {
        id:2,
        name:"Take On Artificial Intelligence",
        description:"This study utilized computational tools and data analytics to systematically compare the portrayal of AI and automation across traditional and social media platforms.",
        github:"https://github.com/UjjvalRajput/Take-On-AI",
        techStack:"Jupyter Notebooks, Python, GraphQL, JSON, NumPy, Pandas, Scikit-Learn, Matplotlib, Seaborn, NLTK, TextBlob",
    },
    {
        id:3,
        name: "Pytube Fetcher",
        description: "Developed in a team of four, this YouTube video downloader utilizes threading for efficient format conversion, achieving 30% efficiency gains. It implements CRUD operations with conversion history and data analysis through visualizations.",
        github: "https://github.com/UjjvalRajput/PytubeFetcher",
        techStack: "Python, Tkinter, Pandas, Matplotlib, Pytube, BeautifulSoup",
    },
    {
        id:4,
        name:"Sokoban in Assembly",
        description:"Implemented the classic puzzle game of Sokoban in Assembly where the player pushes boxes to designated locations. Features include randomized level progression, restart functionality and a win state. Kindly email for a demo or source code.",
        github:"",
        techStack:"Assembly (RISK-V 32-bit ISA), Ripes Simulator",
        show:"mailto:ujjval.rajput@mail.utoronto.ca",
    },
    {
        id:5,
        name: "Mini Unix Shell",
        description: "Developed in a team of two, this unix shell program implements process control, signals, pipes, as well as job management. Implemented features include command parsing, built-in commands (quit, fg, bg, jobs), signal handling (SIGINT, SIGTSTP), input & output redirection, and process pipelines.",
        github: "",
        techStack: "C, Shell scripting, CLI, Unix system calls, signal handling",
        show:"https://youtu.be/fv11fkmZxJA",
    },
    {
        id: 6,
        name: "Space Invaders Game",
        description: "Developed a classic old-school Space Invaders game where the player controls a spaceship to shoot and destroy waves of aliens. Features include multiple levels of difficulty, different types of aliens with powers, score and level tracker, help menu, etc. The game provides an engaging experience with retro-style graphics.",
        github: "https://github.com/UjjvalRajput/Space-Invaders-Game",
        techStack: "Python, Pygame, OOP concepts",
        show: "https://youtu.be/dF095NTXsEI",
    },
    {
        id:7,
        name: "Huffman Coding Compression/Decompression Software",
        description: "Implemented a Huffman coding algorithm for efficient lossless compression and decompression of files. Optimized through strategic node-swapping for faster compression. Integrated a user-friendly GUI with file-dialog interface for seamless file handling. Kindly email for a demo or source code.",
        github: "",
        techStack: "Python, Tkinter, Hypothesis, Pytest",
        show: "mailto:ujjval.rajput@mail.utoronto.ca",
    },
    {
        id:8,
        name: "Text-Emoji Converter",
        description: "A user-friendly web app for encrypting and decrypting text messages using emojis. You can use it like me to send secret messages to your friends or decode messages you receive. The app is designed to be simple and easy to use, with a clean and intuitive interface.",
        github: "https://github.com/UjjvalRajput/Text-And-Emoji",
        techStack: "HTML, CSS, JavaScript",
        show: "https://ujjvalrajput.github.io/Text-And-Emoji/",
    },
    {
        id:9,
        name:"LearnAcademy: High-Fidelity Prototype",
        description:"Designed a high-fidelity prototype for an online education management application to improve the online teaching experience of instructors. Features include login/signup, course creation & distribution, monetization & analytics, subscriber management, user profile & account management, communication center, and media upload.",
        show: "https://xd.adobe.com/view/d013aa1c-4f42-4122-abb6-fa1159b08e68-8540/",
        techStack:"Adobe XD, User Experience Design, User Interface Design, Wireframing, Prototyping",
    },
    {
        id:10,
        name:"Lowering Shopping Cart Abandonment Rates",
        description:"Built a medium-fidelity prototype for a clothing website designed to conduct contextual inquiries and participatory design with the goal of lowering shopping cart abandonment rates. The app features product search, detailed product reviews, ratings, price comparisons, recommendations, and an AI chatbot to enhance the user experience.",
        show: "https://www.figma.com/design/AUOrefo1jQsNGOPGrpTmFN/Low-fidelity-Prototype-of-Total-Price-%26-360-Review?node-id=24-1261&t=sIZaEQnSJQpPrj5t-1",
        techStack:"Figma, User Experience Design, User Interface Design, Wireframing, Prototyping",
    },
    {
        id:11,
        name:"Campus Connect: High-Fidelity Prototype",
        description:"Designed a high-fidelity prototype of a University of Toronto social networking application to enhance student engagement. Features include light and dark mode, login/signup & registration, profile creation, home page, detailed discover pages, event creation & management, group creation & management, chat functionality, and notification center.",
        show: "https://xd.adobe.com/view/cc3f12be-5167-46f8-b6c8-2e626b3a48ba-996c/",
        techStack:"Adobe XD, User Experience Design, User Interface Design, Wireframing, Prototyping",
    },
    {
        id:12,
        name:"Personal Website (This Website)",
        description:"Designed and developed a responsive portfolio site to showcase my projects and skills. Features include a home page, about page, and a projects page.",
        github:"",
        techStack:"React, Tailwind CSS",
    }
]